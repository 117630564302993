export default {
  tableFields: [
    {
      name: '__component:badge-column',
      title: '',
      dataClass: 'text-center',
      width: '4%'
    },
    {
      name: 'name',
      title: 'Nombre',
      sortField: 'name',
    //  width: '24%'
    },
    {
      name: 'number',
      title: 'Numero',
      sortField: 'name',
    //  width: '24%'
    },
    {
      name: 'type_product.name',
      title: 'Tipo',
      sortField: 'type_name',
    //  width: '24%'
    },
    {
      name: '__slot:promotion',
      title: 'Promoción',
      titleClass: 'center aligned',
      dataClass: 'center aligned',
     // width: '24%',
    },
    {
      name: '__slot:active',
      title: 'Acciones',
      titleClass: 'center aligned',
      dataClass: 'center aligned',
     // width: '24%',
    },
  ],
  sortFunctions: {
    'name': function (item1, item2) {
      return item1 >= item2 ? 1 : -1
    },
  }
}
